.highlight-card {
  border-radius: 20px !important;
  &.small {
    height: 300px;
    width: 200px;
    border-radius: 3px;
  }
  &.large {
    width: 270px;
    height: auto;
  }
  hr {
    padding: 0;
    margin-top: 0;
    margin-bottom: 8px;
  }
  p {
    font-size: 0.9rem;
    font-weight: 500;
  }
  .title {
    font-family: "Montserrat";
    color: rgba(93, 80, 171);
  }
  .text {
    color: #f999a0;
  }
}
.icon-wrapper {
  padding: 3%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #d0e0f6;
  margin-right: 5px;
  .icon {
    width: 30px;
    height: 30px;
  }
}

.num-stats {
  width: 100px;
  height: 100px;
}

.share-feature-btn {
  order: 1;
  flex: 0 1 auto;
  align-self: flex-end;
}

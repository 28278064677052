/* add css module styles here (optional) */

.comments-section {
  display: flex;
  flex-direction: column;
  width: 100%;

  .inputBox {
    width: 100%;
  }

  .inputActions {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    margin-left: 15px;

    .postBtn {
      color: white;
      text-align: center;
      background-size: 200% auto;
      border-radius: 5px;
      transition: 0.5s;
      padding: 8px 16px;
      background-color: #84dcff;
      border: none;
      margin-right: 10px;
      font-weight: bolder;
      font-size: 16px;

      &:hover {
        background-position: right center;
        cursor: pointer;
      }

      &:focus {
        outline: 0;
      }
    }

    .cancelBtn {
      color: rgb(139, 139, 139);
      text-align: center;
      background-size: 200% auto;
      border-radius: 5px;
      transition: 0.5s;
      padding: 8px 10px;
      border: 2px solid rgb(139, 139, 139);
      font-weight: bolder;
      font-size: 16px;

      &:hover {
        background-position: right center;
        cursor: pointer;
      }

      &:focus {
        outline: 0;
      }
    }
  }
}

.comments-form {
  display: flex;
  background-color: rgb(243, 243, 243);
  padding: 8px;
  padding-bottom: 15px;
  border-radius: 8px;
  .userImg {
    display: flex;
    width: 10%;
    justify-content: center;
    align-items: center;
  }

  .postComment {
    width: 80%;
    border: none;
    border-bottom: 1px solid rgb(24, 24, 24);
    text-decoration: none;
    background-color: transparent;
    margin-left: 4px;
  }
  .postComment:focus {
    outline: none;
    border-bottom: 2px solid rgb(14, 14, 14);
  }
  .postComment::placeholder {
    margin-top: -2px;
  }
}

.displayComments {
  margin-top: 18px;
  margin-left: 38px;

  .halfDiv {
    display: flex;
    justify-content: space-between;
    .userInfo {
      display: flex;
      flex-direction: column;
      margin-top: 16px;

      .commentsTwo {
        margin-top: 8px;
        margin-right: 35px;

        .comment-text {
          font-size: medium;
        }

        .fullName {
          display: flex;
          gap: 0px;
        }
        .app-stats-tinder {
          background-color: #1bc4f8;
          border-radius: 5px;
        }
        .app-stats-hinge {
          background-color: #8060ec;
          border-radius: 5px;
        }
        .match-score {
          // background-color: orange;
          border-radius: 5px;
          font-size: medium;
        }
        .reply-score {
          // background-color: green;
          border-radius: 5px;
          font-size: medium;
        }
        .app-logo {
          height: 20px;
          width: 20px;
          margin-top: 1px;
          margin-right: 2px;
        }
        .orchard-points {
          background-color: lightgray;
          color: #243762;
          border-radius: 5px;
          .peach-badge {
            height: 20px;
            width: 20px;
            margin-top: 1px;
            margin-right: 2px;
          }
        }
        .verified-badge {
          height: 20px;
          width: 20px;
          margin-top: 1px;
          // margin-right: 2px;
        }

        .replyBtn {
          background-color: transparent;
          border: none;
          color: gray;
          outline: none;
          font-weight: 600;
          font-size: 14px !important;
          // margin: 0px 5px !important;
          // width: 70px;
          // padding: 5px !important;
          border-radius: 4px;

          &:hover {
            outline: none;
            background-color: rgba(160, 160, 160, 0.315);
          }
          &:focus {
            outline: 0;
          }
        }
        .up-vote-button,
        .down-vote-button {
          background-color: transparent;
          border: none;
          color: gray;
          outline: none;
          font-weight: 600;
          font-size: 14px !important;
          // margin: 0px 5px !important;
          // width: 70px;
          // padding: 5px !important;
          border-radius: 4px;

          &:hover {
            outline: none;
            background-color: rgba(160, 160, 160, 0.315);
          }
          &:focus {
            outline: 0;
          }
        }
        .vote-number {
          color: gray;
          font-weight: 600;
          font-size: 14px !important;
        }
      }
    }
    .gray-text {
      color: gray;
      outline: none;
      font-weight: 600;
      font-size: 14px !important;
    }
    .userActions {
      .dropdown-toggle:after {
        display: none;
      }

      .dropdown-menu {
        min-width: 0px;
      }
      .dropdown-item {
        font-size: 14px;
        text-align: center;
        // height: 14px;
        // margin: 1px;
        // padding: 1px;
      }

      .btn {
        background-color: transparent !important;
        border: none;
        // padding: 6px 12px;
        // border-radius: 50%;
        cursor: pointer;

        &:hover {
          outline: none;
          background-color: #dbdbdb5d !important;
        }
        &:focus {
          outline: 0 !important;
        }
      }
    }
  }

  .replySection {
    margin-left: 35px;
    border-left: 2px solid rgb(199, 199, 199);
    margin-top: 6px;
  }
}

.actionDiv {
  width: 0px !important;

  .editBtn {
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
  }
  .deleteBtn {
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
  }
}

.signBox {
  border: 1px solid rgb(221, 221, 221);
  border-radius: 8px;
  background-color: transparent;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  width: 80%;

  .signLine {
    margin-top: 5px;
    font-weight: 500;
    color: rgb(156, 156, 156);
    font-size: 17px;
  }

  .loginBtn {
    border: 2px solid rgb(0, 195, 255);
    border-radius: 8px;
    background-color: transparent;
    padding: 5px 10px;
    color: rgb(0, 195, 255);
    font-weight: bolder;
    margin-right: 10px;
    font-size: 16px;
    &:active {
      outline: none;
      border: none;
    }
  }
  .signBtn {
    border: 2px solid rgb(0, 195, 255);
    border-radius: 8px;
    background-color: rgb(0, 195, 255);
    padding: 5px 10px;
    color: rgb(255, 255, 255);
    font-weight: bolder;
    font-size: 16px;
    &:active {
      outline: none;
      border: none;
    }
  }
}

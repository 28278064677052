.card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15em;
  min-height: 15em;
  h6 {
    font-size: 0.9em;
    text-overflow: wrap;
  }
}

.map-key {
  width: 20px;
  height: 20px;
  border-radius: 4px;
}
.avoid-word-btn {
  border: 1px solid #f2627f;
}
.word-cloud {
  overflow-x: auto;
}

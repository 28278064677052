.message-bubble {
  background-color: #3598da;
  color: white;
  border-radius: 15px 15px 5px 15px;
  text-align: left;
  position: relative;
}

.message-bubble-last {
  background-color: #3598da;
  color: white;
  border-radius: 15px 15px 5px 15px;
  text-align: left;
  position: relative;
  // &:after {
  //   border-left: 20px solid transparent;
  //   border-top: 20px solid #3598da;
  //   bottom: -20px;
  //   content: "";
  //   position: absolute;
  //   right: 20px;
  // }
}

.triangle {
  content: "";
  position: absolute;
  z-index: 1002;
  height: 30px;
  background-color: #ff0d1e;
  transform: skew(55deg);
  transform-origin: top right;
  border-radius: 15% 0 0 0 / 25%;
  z-index: -1;
}

.delete-icon {
  z-index: 1001;
  top: -10px;
  left: -10px;
}

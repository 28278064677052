.footer-component {
  .brand {
    img {
      display: block;
      height: 60px;
    }
  }

  .footer-link {
    text-decoration: none;
    color: black;
    margin: 10px 0;
    &:hover {
      color: pink;
    }
  }
  .footer-links {
    text-align: center;
  }
}

@media (min-width: 767px) {
  .footer-component {
    .footer-links {
      text-align: right;
    }
  }
}

.image-container,
.inner-container {
  width: 250px;
  height: 250px;
  .voted-alert {
    position: absolute;
    // height: 50px;
    // width: 50px;
    z-index: 1005;
    // background-color: yellow !important;
    // color: #ffcb23;
    color: #f48c06;
    top: 0;
    left: 250px;
  }
  .multiple-status {
    position: absolute;
    height: 30px;
    width: 30px;
    z-index: 1005;
    top: 25px;
    left: 25px;
  }
}

.carousel .control-dots .dot {
  background: #495057 !important;
}
.carousel .carousel-status {
  text-shadow: none !important;
  font-size: 1em !important;
  font-weight: bolder !important;
  color: white !important;
  background-color: #495057 !important;
  border-radius: 10px;
  z-index: 5;
  // text-shadow: 1px 1px 1px white Im !important;
}

.carousel {
  max-height: 600px;
  .carousel-child {
    // margin-bottom: 20px;
    width: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.image-details-wrapper {
  border-style: solid;
  border-width: 2px;
}

.upload-form {
  margin: 30px auto 10px;
  text-align: center;
}
.upload-form label input {
  height: 0;
  width: 0;
  opacity: 0;
}
// .upload-form label {
//   display: block;
//   width: 30px;
//   height: 30px;
//   border: 1px solid var(--primary);
//   border-radius: 50%;
//   margin: 10px auto;
//   line-height: 30px;
//   color: var(--primary);
//   font-weight: bold;
//   font-size: 24px;
// }
.upload-form .fa-icon:hover {
  color: #494f56;
  transform: scale(1.2);
}
.output {
  height: 60px;
  font-size: 0.8rem;
}

/* progress bar styles */
.progress-bar {
  color: #494f56 !important;
  height: 5px;
  margin-top: 20px;
}

.api-logo {
  width: 40%;
  height: 40%;
}

.score-foot-note {
  line-height: 80%;
}

.quality-score-bar {
  background-color: lightgray;
  border-radius: 5px;
  // overflow: hidden;
  .quality-score {
    background-color: #7000ff;
    color: white;
    text-align: center;
    border-radius: 5px;
  }
}

.side-navbar {
  position: sticky;
  left: 0;
  top: 150px;
  z-index: 1005;
  height: 100vh;
}
// .side-nav-btn {
//   position: fixed;
// }
.navbar-link {
  align-self: start;
  display: flex !important;
  align-items: center;
  font-size: 1.1rem;
  color: #000;
  width: 100%;
  &:hover {
    background-color: #eff2f5;
    color: black;
  }
  &.main {
    margin-top: 20px;
  }

  .icon-wrapper {
    width: 30px;
    position: relative;
    background-color: transparent;
    .icon {
      width: 25px;
      height: 25px;
    }
  }
  .larger-icon {
    font-size: 1.6em;
  }
}
.close-btn {
  top: 0px;
  position: relative;
}

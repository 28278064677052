.score-bar {
  position: relative;
  border-radius: 15px;
  border-radius: 15px;
  z-index: 1001;
  overflow: hidden;
  min-height: 70px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  background-color: #f0f0f0;
  .score {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;

    &.good {
      background-color: #cbf0b3;
    }
    &.bad {
      background-color: rgb(231, 152, 174);
    }
  }
}

.nav-tabs .nav-link {
  font-size: x-large;
  font-weight: 600;
  color: #219ebc;
  // border: 2px solid gray;

  &[id^="orchard-tab"] {
    font-size: large;
  }
}

.nav-tabs .nav-link:hover {
  color: #393a3d;
}

.nav-tabs .nav-link:active {
  background-color: #393a3d;
  color: white;
}

.nav-tabs {
  border-bottom: none;
}

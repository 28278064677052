.QuoteSection__image-container {
  width: 100%;
  min-height: 400px;
}

@media (max-width: 400px) {
  .QuoteSection__image-container {
    width: 100%;
    height: 200px;
  }
}

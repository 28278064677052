.funnel-level-wrapper {
  width: 75%;
}
.funnel-level {
  border-radius: 13px;
  left: 0;
  right: 0;
  height: 100px;
}

.funnel-text {
  font-weight: 700;
  font-size: 1.2rem;
}
.badge {
  background-color: #bbdefb;
  color: #1976d2;
  height: 25px;
  width: 25px;
  position: relative;
  top: -10px;
  left: 10px;
}

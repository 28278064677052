.anonymous-user-modal {
  position: fixed;
  bottom: 0;
  z-index: 10000;
  background-color: #067bfe;
  color: white;
  display: flex;
  border-left-style: solid;
  border-left-color: #0763c6;
  border-left-width: 5px;
  .color-border {
    height: 100%;
    width: 5px;
    background-color: darkblue;
  }
}

.vote-card-wrapper {
  .message {
    min-height: 350px;
  }
  .score-bar {
    position: relative;
    border-radius: 15px;
    border-radius: 15px;
    z-index: 1001;
    overflow: hidden;
    min-height: 70px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    background-color: #f0f0f0;
    .score {
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;

      &.good {
        background-color: #cbf0b3;
      }
      &.bad {
        background-color: rgb(231, 152, 174);
      }
    }
  }
  .card-header {
    max-height: 100px;
    overflow: scroll;
    background-color: #495057;

    // color: white;
  }
  .tag-box {
    text-align: left;
    background-color: #fefae0;
    color: black;
    border-radius: 0px 20px 20px 0px;
    box-shadow: 2px 2px 1px grey;
    position: relative;
  }

  .fa-comment:hover {
    color: gray !important;
  }
}

.sidepanel {
  width: 530px;
  position: fixed;
  z-index: 2001;
  height: 100%;
  top: 0;
  /* bottom: 0; */
  right: 0;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.25) 3px 0px 12px 3px;
  overflow: hidden;
  overflow-y: scroll;
  transition: 0.5s;
  padding-top: 60px;
}

.sidepanel .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
}

.vote-icon,
.comment-icon {
  &:hover {
    transform: scale(1.3);
  }
}

.quiz-wrapper {
  background-color: #f8f4eb;
  border-radius: 25px;
  min-height: 450px;
  width: 100%;
  border-style: solid;
}
#option-btn {
  background-color: #e798ae;
  color: white;
  font-weight: 500;
  font-size: 1em !important;
  min-width: 200px;
  &:hover {
    background-color: #fed1ce !important;
    color: black;
  }
}

.quiz-icon {
  position: absolute;
  right: -12px;
  top: -8px;
  width: 30px;
}

.quiz-btn {
  position: relative;
}
.last-page-quiz {
  position: relative;
  width: 100%;
}

@charset "utf-8";
@font-face {
  font-family: "Futura Book";
  src: local("Futura Book"),
    url("../assets/fonts/Futura\ Book.ttf") format("truetype");
}
// COLORS

$yellow: #ffcd00;
$pink: #f2627f;
$med-pink: #e798ae;
$peach: #ffe7d9;
$salmon: #f9a159; //#fba59f;
$light-pink: #fcdbca;
$light-gray: #f1f1f3;
$black: #534f5e;

* {
  box-sizing: border-box;
}

#root {
  font-family: "Montserrat", sans-serif;
}

p {
  font-family: "Futura Book";
  font-size: 1.2em;
}
h5 {
  color: $salmon;
  font-weight: 700;
}
Button {
  font-size: 1.1em !important;
  border-radius: 3px !important;
  padding: 14px 15px !important;
}
.btn-primary {
  background-color: $pink !important;
  border: none !important;
}

.btn-legend {
  color: $black !important;
  font-size: 0.9em !important;
  background-color: #ffeeba !important;
  border: none !important;
  display: inline-block;
}

.btn-outline {
  border: 2px solid $pink !important;
  background-color: white !important;
  color: $pink !important;
}

.rounded-corners {
  border-radius: 15px !important;
}
textarea:focus,
input:focus {
  outline: none;
}
Form.Label {
  font-weight: 700 !important;
  color: blue !important;
}

.anchor {
  position: relative;
  top: -270px;
  visibility: hidden;
}

.shadow-custom {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.main-wrapper {
  padding-top: 85px;
}
.pointer {
  cursor: pointer;
}

.role-card {
  height: 400px !important;
  width: 400px !important;
  border-radius: 50px !important;
  cursor: pointer;
  // background-color: #f8f4eb !important;
  // background-color: mix(#fff, #f8f4eb, 99%) !important;
  background-color: mix(#fff, #494f56, 75%) !important;
  box-shadow: 5px 5px 10px 5px #ffcb23;
  &:hover {
    background-color: #f8f4eb !important;
  }
  .role-card-body {
    display: flex;
    align-items: center;
    h1 {
      color: mix(#fff, #494f56, 25%);
      // font-size: calc(1em + 20vw);
      font-size: 2.8em;
      font-weight: 900;
      text-shadow: -0.0075em 0.0075em 0 mix(#fff, #494f56, 94%),
        0.005em 0.005em 0 mix(#fff, #494f56, 60%),
        0.01em 0.01em 0 mix(#fff, #494f56, 62%),
        0.015em 0.015em mix(#fff, #494f56, 64%),
        0.02em 0.02em 0 mix(#fff, #494f56, 66%),
        0.025em 0.025em 0 mix(#fff, #494f56, 68%),
        0.03em 0.03em 0 mix(#fff, #494f56, 70%),
        0.035em 0.035em 0 mix(#fff, #494f56, 72%);
    }
  }
}

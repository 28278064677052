// .side-nav-btn {
//   position: fixed;
//   right: 10px;
//   top: 35px;
//   z-index: 1003;
// }
.auth-btn {
  width: 120px;
  height: 35px;
  &:hover {
    background-color: #e5e5e5 !important;
  }
}

.top-nav {
  z-index: 2000;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.nav-right {
  display: flex;
}
